// extracted by mini-css-extract-plugin
export var main = "about-module--main--3X_RB";
export var column = "about-module--column--3wTsd";
export var skills = "about-module--skills--1VXc4";
export var skill = "about-module--skill--1eQXk";
export var skillHeader = "about-module--skill-header--d43uX";
export var time = "about-module--time--14l4J";
export var title = "about-module--title--2Yr71";
export var skillImage = "about-module--skill-image--2r-R5";
export var contact = "about-module--contact--PqlEg";
export var intro = "about-module--intro--1LgWa";
export var text = "about-module--text--9wUYf";
export var profilePictureWrapper = "about-module--profile-picture-wrapper--28Llc";
export var profilePicture = "about-module--profile-picture--14don";