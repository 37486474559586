import React, {useLayoutEffect, useRef} from "react";
import Seo from "../../components/seo/seo";
import Nav from "../../components/nav/nav";
import * as styles from "./about.module.scss";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {gsap} from "gsap";
import {graphql, Link} from "gatsby";


const SKILLS = [
  {
    name: "HTML/CSS",
    iconName: "html-css",
    start: "2016-08-01",
    text: "Making content for web is my job, so I dare to say I have a deeper understanding of HTML and " +
      "CSS (as well as other preprocessors such as SCSS)."
  },
  {
    name: "Javascript",
    iconName: "javascript",
    start: "2018-01-01",
    text: "In the beginning, I used Javascript only for minor tweaks in functionality. But lately I've " +
      "been using it more and more, because in my opinion, it is crucial for building modern web apps."
  },
  {
    name: "Angular",
    iconName: "angular",
    start: "2019-01-01",
    text: "This is my go-to framework for building large web projects. What I like about it " +
      "the most is the scalability it provides."
  },
  {
    name: "React",
    iconName: "react-js",
    start: "2021-01-01",
    text: "When I do smaller web projects I usually choose React. The great thing about it is that there" +
      " are many paths to choose when using react for a project, because I can choose to use many " +
      "of its libraries to build exactly according to the project's needs."
  },
  {
    name: "GatsbyJS",
    iconName: "gatsbyjs-icon",
    start: "2021-06-01",
    text: "GatsbyJS is currently my favourite technology for building smaller websites and portfolios. " +
      "I actually built this website using GatsbyJS."
  },
  {
    name: "NextJS",
    iconName: "nextjs",
    start: "2021-05-01",
    text: "When I needed something for a project with heavy backend and loading a lot of content on " +
      "the frontend, I chose NextJS. The pre-rendering and server-side-rendering of this framework " +
      "were the perfect fit."
  },
  {
    name: "Swift",
    iconName: "swift",
    start: "2019-01-01",
    text: "Developing mobile apps is also a great passion of mine. I mainly develop for iOS devices. For " +
      "that I use the Swift programming language. I also use SwiftUI from the early moments of its " +
      "development and quickly fell in love with its syntax."
  },
  {
    name: "Python",
    iconName: "python",
    start: "2017-05-01",
    text: "Sometimes I need to create a small script or calculate some complex stuff. I use Python for " +
      "that. It's not about the knowledge of the language itself, but more about that I can " +
      "come up with efficient algorithms for the current problem."
  },
  {
    name: "Git",
    iconName: "git",
    start: "2018-09-01",
    text: "There is no dispute about the fact that version control is a big part of product development. " +
      "Luckily, I started working with git early on and have been actively using it since then. I use GitHub" +
      " for all my projects not only for version control, but also for easier work in teams and continuous" +
      " deployment on various platforms."
  },
  {
    name: "Figma",
    iconName: "figma-icon",
    start: "2020-05-01",
    text: "For creating designs for my personal or client projects, I use Figma. I am able to quickly " +
      "draw my ideas and even prototype complex animations. This way my creativity is not limited."
  },
];


const AboutPage = ({data}) => {
  const text = useRef();
  const picture = useRef();
  const intro = useRef();

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: intro.current,
        start: "top top",
        end: `+=1000`,
        markers: false,
        scrub: true,
        pin: true,
        invalidateOnRefresh: true
      },
    })

    tl.fromTo(intro.current, {
      backgroundPosition: "0",
    }, {
      backgroundPosition: "100%"
    });

    tl.fromTo(text.current, {
      x: 0,
    }, {
      x: -(text.current.scrollWidth) + window.innerWidth - 100
    }, "<");

    return () => {
      tl?.kill();
    };
  }, []);

  function getTime(dateString) {
    const start = new Date(dateString);
    const monthsSince = Math.floor((Date.now() - start.getTime()) / (1000 * 3600 * 24 * 365 / 12));

    if (monthsSince < 6) {
      return `${monthsSince} months`;
    } else if (monthsSince < 12) {
      return "half a year";
    } else if (monthsSince < 24) {
      return "1 year";
    } else if (monthsSince >= 24) {
      return `${Math.floor(monthsSince / 12)} years`;
    } else {
      return ""
    }
  }

  const skills = SKILLS;
  skills.map(
    skill => skill.icon = getImage(data.allFile.edges.find(
      edge => edge.node.name === skill.iconName
    ).node)
  );

  return (
    <>
      <Seo whiteBody={true} />
      <Nav />

      <div>
        <div className={styles.intro} ref={intro}>
          <div className={styles.profilePictureWrapper} ref={picture}>
            <StaticImage
              src="../../images/profile-picture.png"
              alt="profile picture"
              className={styles.profilePicture}
              objectPosition={"top center"}
              loading={"eager"}
            />
          </div>

          <div className={styles.text} ref={text}>
            <h2>Development</h2>
            <h2>Creativity</h2>
            <h2>Design</h2>
          </div>
        </div>
      </div>

      <main className={styles.main}>
        <section>
          <h1>Who am I</h1>
          <div>
            <p>My name is Martin Lédl and creating digital products is my passion. I always try to come up with new and
              inovative ideas. Lately I've been making websites and portfolios for a variety of clients. If you'd like to
              work with me, don't hesitate to <Link to={"/about#contact"}>contact me.</Link>
            </p>
            <p>
              I started making websites about {getTime("2016-08-01")} ago. The beginnings were rough
              as I had no clue about UI/UX design. But since then I've worked on improving not only the
              designing process, but mainly the technical skills required for building all kinds of web projects.
            </p>
            <p>
              When I create websites for clients, I make sure I always understand their desired goals.
              Since the start of my career I've received only great feedback from them and some clients are
              even coming back to me with requests on help with other digital products.
            </p>
          </div>
        </section>
        <section className={styles.column}>
          <h2>My skills</h2>
          <div className={styles.skills}>
            {skills.map((skill, index) => (
              <div key={`${skill.name}-${index}`}>
                <div className={styles.skill}>
                  <div className={styles.skillHeader}>
                    <div className={styles.title}>
                      <GatsbyImage image={skill.icon}
                                   alt={`${skill.name} icon`}
                                   objectFit={"contain"}
                                   className={styles.skillImage}
                      />
                      <h4>{skill.name}</h4>
                    </div>
                    <h4 className={styles.time}>{getTime(skill.start)}</h4>
                  </div>
                  <p>{skill.text}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section data-navigation-id="contact" className={styles.contact}>
          <h3><a href="mailto:martinledl.tech@gmail.com">martinledl.tech@gmail.com</a></h3>
          <h3><a href="tel:+420 777 803 934">+420 777 803 934</a></h3>
        </section>
      </main>
    </>
  )
}

export const pageQuery = graphql`
    query {
        allFile(filter: {relativePath: {glob: "icons/*"}}) {
            edges {
                node {
                    name
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default AboutPage;
